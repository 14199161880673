import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import NavBarSN from "../components/SchreinereiNebe/NavBar_SN"
import FooterSN from "../components/SchreinereiNebe/Footer_SN"
import OurQualities from "../components/SchreinereiNebe/OurQualities"
import Galleries from "../components/SchreinereiNebe/Galleries"
import BrandName from "../components/SchreinereiNebe/BrandName"
import BannerSN from "../components/SchreinereiNebe/Banner_SN"
import BlueBackButton from "../components/BlueBackButton"
import FakePageDisclaimer from "../components/FakePageDisclaimer"

export default function schreinereiNebe({data}){

    const navButtons = data.allSchreinereiNebeJson.nodes[0].navButtons;
    const Footer = data.allSchreinereiNebeJson.nodes[0].Footer;
    const ourQualities = data.allSchreinereiNebeJson.nodes[0].OurQualities;
    const galleriesData = data.allSchreinereiNebeJson.nodes[0].Galleries;
    const BrandNameData = data.allSchreinereiNebeJson.nodes[0].BrandName;
    const BannerData = data.allSchreinereiNebeJson.nodes[0].Banner;

    return(
        <div>
            <Helmet>
                <meta name="robots" content="noindex" charSet="utf-8"/>
                <title>Website Design Beispiel</title>
                <html lang="de"/>
            </Helmet>
            <BlueBackButton/>
            <NavBarSN data={navButtons}/>
            <BannerSN data={BannerData}/>
            <BrandName data = {BrandNameData}/>
            {
                galleriesData.map((Item, i) => (
                    <div key={i}>
                    <Galleries data={Item} tag={i} isLast={(i===galleriesData.length-1)?(true):(false)}/>
                    </div>
                ))
            }
            <OurQualities data = {ourQualities}/>
            <FooterSN data={Footer}/>
            <FakePageDisclaimer data={data.allGlobalJson.nodes[0].Disclaimer}/>
        </div>
      )
}

export const query = graphql`
{ 
    allSchreinereiNebeJson {
        nodes {
            title
            navButtons {
                text
            }
            Banner {
                Tiles {
                    title
                    image {
                        src {
                            childImageSharp {
                                gatsbyImageData(
                                    layout: FULL_WIDTH
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                        alt
                    }
                }
            }
            BrandName {
                preText
                postText
            }
            Galleries {
                header
                subHeader
                text
                buttonText
                slides {
                    src {
                        childImageSharp {
                            gatsbyImageData(
                                width:520
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                    alt
                }
            }
            OurQualities {
                header
                content {
                    text
                    title
                }
            }
            Footer {
                map {
                    src {
                        childImageSharp {
                            gatsbyImageData(
                                layout: FULL_WIDTH
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                    alt
                }
                adresse {
                    strasse
                    ort
                    land
                }
                footer_basics {
                    text
                    link
                }
            }
        }
    }
    allGlobalJson {
        nodes {
            Disclaimer
        }
    }
}
`