import React from "react"
import styled from "styled-components"
import Pricetag from "../../../data/icons/schreinereiNebe/pricetag.svg"
import Ribbons from "../../../data/icons/schreinereiNebe/ribbons.svg"
import Ruler from "../../../data/icons/schreinereiNebe/ruler.svg"
import NebeLinebreak from "../../../data/icons/schreinereiNebe/Nebe_Linebreak.svg"

const Container = styled.div`
top:3em;
position:relative;
width:100%;
margin-bottom:200px;
`
const Header = styled.div`
width:80%;
margin:auto;
text-align:center;
position:relative;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;
width:100%;
margin-bottom:50px;

font-family:Alata;

& svg {
    position:absolute;
    bottom:0;
}
`
const Content = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
-ms-flex-direction:column;
        flex-direction:column;

-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
`
const CItem = styled.div`
max-width:900px;
width:80%;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
-ms-flex-direction:column;
        flex-direction:column;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;
padding:0px;
margin:30px 0px;
font-family:Alegreya Sans;

@media (min-width:993px) {
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    padding-left:${props => props.isLeft?("0"):("100px")};
    padding-right:${props => props.isLeft?("100px"):("0")};
}
`

const Left = styled.div`
margin:0px 50px;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
-ms-flex-direction:column;
        flex-direction:column;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
@media (max-width:993px) {
    -webkit-box-ordinal-group:0;
        -ms-flex-order:-1;
            order:-1;
}
& h1 {
    font-family:Alata;
    font-weight:700;
    font-size:20px;
}
`

export default function OurQualities(props){
    return(
        <Container >
            <Header>
                <h1>{props.data.header}</h1>
                <NebeLinebreak/>
            </Header>
            <Content>
                {
                    props.data.content.map((item, i) => (
                        (i%2===0)?(
                        <CItem key={i} isLeft={true}>
                            <Left>
                                { i === 0 && <Ribbons/> }
                                { i === 1 && <Pricetag/> }
                                { i === 2 && <Ruler/> }
                                <h1>{item.title}</h1>
                            </Left>
                            <p>{item.text}</p>
                        </CItem>
                        ):(
                        <CItem key={i} isLeft={false}>
                            <p>{item.text}</p>
                            <Left>
                                { i === 0 && <Ribbons/> }
                                { i === 1 && <Pricetag/> }
                                { i === 2 && <Ruler/> }
                                <h1>{item.title}</h1>
                            </Left>
                        </CItem>    
                        )
                    ))
                }
            </Content>
        </Container>
    )
}