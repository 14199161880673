import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
/*
Props:
slides = array of images that are supposed to be displayed
speed = speed of the transition
delay = before next slide appears
*/
const offX = 100
const offY = 50
const Container = styled.div`
width:90%;
margin:20px;
overflow:hidden;
`
const Wrapper = styled.div`
margin:auto;
max-width:600px;
width:100%;
height:400px;
@media (min-width:993px) {
  height:500px;
}
position:relative;

& .GatImg {
  display:none;
  position:absolute;
  height:80%;
  width:80%;  
  -webkit-transition:1s ease;  
  -o-transition:1s ease;  
  transition:1s ease;
  border: 10px solid white;
  -o-object-fit:cover;
     object-fit:cover;
  background-color:white;
}

& .GatImg:nth-child(${props => props.current+1}) {
  display:block;
  z-index:1;
  top:0;
  left:0;
}
& .GatImg:nth-child(${props => props.next+1}) {
  display:block;
  top:${offY}px;
  left:${offX}px;
  opacity:65%;
}
& .GatImg:nth-child(${props => props.prev+1}) {
  display:block;
  opacity:0%;
}
`
const Buttons = styled.div`
position:relative;
width:200px;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;
margin:auto;

& span {
  display:block;
  height:1em;
  width:1em;
  background-color:#fff;
  margin:5px;
  border-radius:50%;
  -webkit-box-shadow:0px 0px 1px 1px rgba(0,0,0,0.4);
          box-shadow:0px 0px 1px 1px rgba(0,0,0,0.4);
  -webkit-transition:0.4s ease;
  -o-transition:0.4s ease;
  transition:0.4s ease;
  
  &:hover {
    background-color:#ddd;
    cursor:pointer;
  }
}
& *:nth-child(${props => props.current+1}) { 
  background-color:#ddd;
}
`

class SwitchGallery extends React.Component{
    constructor(props){
      super(props);  
      this.imgList = props.slides
      this.numSlides = (props.slides==null)?(0):(props.slides.length) 
      this.x0 = 0
      this.active = true

      this.delay = (props.delay==null)?(5):(props.delay)
      this.speed = (props.speed==null)?(1):(props.speed)
      this.start = (props.start==null)?(3):(props.start)
      

      this.state = {
        pos:0,
        interrupt:false
      }
    }
    
    componentDidMount(){
      setTimeout(()=> {
        this.autoSwitch();
      },(this.start)*1000)
    }
    componentWillUnmount() {
      this.active=false
  }

    
    autoSwitch() {
      if(this.active) {
        setTimeout(()=> {
          if(this.state.interrupt===false)
            this.switchSlides()
          this.autoSwitch()
          this.setState({interrupt:false})
        },this.delay*1000);  
      }   
    }
    
    switchSlides() {
        if(this.state.pos===this.numSlides-1)
          this.setState({pos:0})
        else
          this.setState({pos:this.state.pos+1})
    }

    switchToSlide(s) {
      if(s<0) {
        if(this.state.pos-1<0)
          this.setState({pos:this.numSlides-1})
        else
          this.setState({pos:this.state.pos-1})
      }
      else {
        if(this.state.pos+1>=this.numSlides)
          this.setState({pos:0})
        else
          this.setState({pos:this.state.pos+1})
      }
  }

    setPos(i) {
        this.setState({
          pos:i,
          interrupt:true
        })
    }

    lockSwipe(e) {
        this.x0 = e.touches[0].clientX
        this.setState({ interrupt:true })
      }

    swipe(e) {
      let dx = e.changedTouches[0].clientX - this.x0
      let s = Math.sign(dx)*(-1)
      if(dx!==0)
        this.switchToSlide(s)
    }
    render() {
        return(
            <Container>
              <Wrapper 
                current={this.state.pos}
                next={(this.state.pos+1===this.numSlides)?(0):(this.state.pos+1)}
                prev={(this.state.pos===0)?(this.numSlides-1):(this.state.pos-1)}

                onTouchStart = {(e) => this.lockSwipe(e)}
                onTouchEnd = {(e) => this.swipe(e)}

                >
                {
                  this.imgList.map((Item, i) => (
                    <GatsbyImage className="GatImg" key={i} image={getImage(Item.src)} alt={Item.alt}/>
                  ))
                }
              </Wrapper>
              <Buttons current={this.state.pos}>
                {
                  this.imgList.map((Item, i) => (
                    <span key={i} role="button" tabIndex={0} onClick={this.setPos.bind(this,i)}  onKeyDown={this.setPos.bind(this,i)}></span>
                  ))
                }
              </Buttons>
            </Container>
        );
    }
}
export default SwitchGallery;