import React from "react"
import styled from "styled-components"
import NebeLinebreak from "../../../data/icons/schreinereiNebe/Nebe_Linebreak.svg"
import NebeAB1 from "../../../data/icons/schreinereiNebe/Nebe_AB1.svg"
import NebeAB2 from "../../../data/icons/schreinereiNebe/Nebe_AB2.svg"
import ShakingDiv from "../LenaStrauß/ShakingDiv"
import SwitchGallery2 from "./SwitchGallery2"

const Container = styled.div`
`
const Wrapper = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
-ms-flex-flow:column;
        flex-flow:column;
margin:70px 0px;
& .gridObject1 {
    width:100%;
}

@media (min-width:993px) {
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
    -ms-flex-flow:row wrap;
    flex-flow:row wrap;
    ${props => (props.isLeft)?(
        '& .gridObject1 { -webkit-box-ordinal-group:1; -ms-flex-order:0; order:0; width:50%; } & .gridObject2 { -webkit-box-ordinal-group:2; -ms-flex-order:1; order:1 }'
    ):(
        '& .gridObject1 { -webkit-box-ordinal-group:2; -ms-flex-order:1; order:1; width:50%; } & .gridObject2 { -webkit-box-ordinal-group:1; -ms-flex-order:0; order:0 }'
    )}
}

-webkit-box-align:center;
-ms-flex-align:center;
align-items:center;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;
position:relative;
width:100%;
`
const TextContent = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
-ms-flex-direction:column;
        flex-direction:column;
max-width:400px;
width:90%;

font-family:Alegreya Sans;
font-size:16px;

& .button {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:end;
        -ms-flex-pack:end;
            justify-content:flex-end;
    margin-right:60px;
}
`
const Header = styled.div`
width:240px;
margin:auto;
& * {
    width:100%;
    margin:0;
}
& h3 {
    font-family:Alata;
    font-size:30px;
}   
& p {
    font-family:Alegreya Sans;
    font-size:20px;
    text-align:right;
}
`
const Linebreak = styled.div`
display:${props => (props.isLast)?("none"):("-webkit-box")};
display:${props => (props.isLast)?("none"):("-ms-flexbox")};
display:${props => (props.isLast)?("none"):("flex")};
justify-content:center;
`
const TerminButton = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
position:relative;
& p {
    color:#BE0707;
    font-family:Alata;
    font-size:18px;
    font-weight:500;
    margin-right:10px;
}


& div svg {
    top:26%;
    position:absolute;
    -webkit-transition:0.4s ease;
    -o-transition:0.4s ease;
    transition:0.4s ease;
}
&:hover div svg:nth-child(2) {
    -webkit-transform:translate(5px, 0px);
        -ms-transform:translate(5px, 0px);
            transform:translate(5px, 0px);
}
`

export default function Galleries(props){
    
    const slides = props.data.slides
    const header = props.data.header
    const subHeader = props.data.subHeader
    const text = props.data.text
    const buttonText = props.data.buttonText

    return(
        <Container>
            { 
                <Wrapper isLeft={(props.tag%2===0)?(true):(false)}>
                    <div className="gridObject1">
                        <SwitchGallery2 slides={slides} start={(props.tag%2===0)?(5):(7)}/>
                    </div>
                    <TextContent className="gridObject2">
                        <Header>
                            <h3>{header}</h3>
                            <p>{subHeader}</p>
                        </Header>
                        <p>
                            {text}
                        </p>
                        <div className="button">
                            <ShakingDiv>
                                <TerminButton>
                                    <p>{buttonText}</p>
                                    <div>
                                        <NebeAB2/>
                                        <NebeAB1/>
                                        
                                    </div>
                                </TerminButton>
                            </ShakingDiv>
                        </div>
                    </TextContent>
                </Wrapper> 
            }
            
            <Linebreak isLast={props.isLast}>
                <NebeLinebreak/>
            </Linebreak>
        </Container>
        
    )
}
