import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ShakingButton from "./ShakingButton"
import Instagram from "../../../data/icons/schreinereiNebe/instagram.svg"
import Twitter from "../../../data/icons/schreinereiNebe/twitter.svg"
import Snapchat from "../../../data/icons/schreinereiNebe/snapchat.svg"


const Container = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
position:relative;
width:100%;
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
-ms-flex-flow:row wrap;
        flex-flow:row wrap;
-webkit-box-pack:justify;
-ms-flex-pack:justify;
        justify-content:space-evenly;
font-family:Alegreya Sans;

& * {
    -ms-flex-preferred-size:100%;
        flex-basis:100%;
    @media(min-width:993px) {
        -ms-flex-preferred-size:20%;
            flex-basis:20%;
    }
}
`
const Line = styled.div`
position:absolute;
top:10%;
width:100%;
height:1px;
background-color:#ddd;
z-index:-1;
`

const Map = styled(GatsbyImage)`
-ms-flex-preferred-size:100%;
        flex-basis:100%;
    @media(min-width:993px) {
        -ms-flex-preferred-size:40%;
            flex-basis:40%;
    }
border: 20px solid #fff;
width:100%;
height:100%;
& img {
    width:100%;
    height:100%;
    -webkit-filter:grayscale(90%);
    filter:grayscale(90%);
}

`
const Adresse = styled.p`
-ms-flex-item-align:center;
-ms-grid-row-align:center;
    align-self:center;
text-align:center;
@media(min-width:993px) {
    text-align:left;
}
margin-bottom:40px;
`
const RightBlock = styled.div`
& p {text-align:center; }
@media(min-width:993px) {
    text-align:left;
}
position:relative;
-ms-flex-item-align:center;
    align-self:center;
right:0;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
-ms-flex-direction:column;
        flex-direction:column;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;

`
const Socials = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
        justify-content:center;

& svg {
    height:100px;
    width:100px;
}

`
const FooterBasics = styled.div`
& p {
    margin:0;
}

`

export default function Footer_SN(props){
    return(
        <Container >
            <Map image={getImage(props.data.map.src)} alt={props.data.map.alt}/>
            
            <Adresse>
                {props.data.adresse.strasse}<br/>
                {props.data.adresse.ort}<br/>
                {props.data.adresse.land}
            </Adresse>
            <RightBlock>
                <Socials>
                    <Snapchat/>
                    <Instagram/>
                    <Twitter/>
                </Socials>
                <FooterBasics>
                    <ShakingButton>Impressum</ShakingButton>
                    <ShakingButton>Datenschutz</ShakingButton>
                    <p>© Kudlik & Pfeifer GbR</p>
                </FooterBasics>
            </RightBlock>
            <Line/>
        </Container>
    )
}