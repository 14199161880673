import React from "react"
import styled from "styled-components"
import NebeLogo from "../../../data/icons/schreinereiNebe/Nebe_Logo.svg"

const Container = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
-ms-flex-direction:column;
        flex-direction:column;
margin:auto;
padding-top:3em;
width:80%;
max-width:600px;

@media (min-width:993px) {
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    max-width:900px;
    & *:nth-child(2) {
        width:400px;
    }
}

& p {
    width:100%;
    font-size:2em;
    font-family:Alegreya Sans;
}
& *:nth-child(1) {
}
& *:nth-child(2) {
    margin:auto;
}
& p:nth-child(3) {
    text-align:right;
`

export default function BrandName(props){
    return(
        <Container>
            <p>{props.data.preText}</p>
            <NebeLogo/>
            <p>{props.data.postText}</p>
        </Container>
    )
}