import React from "react"
import styled from "styled-components"
import NebeLogo from "../../../data/icons/schreinereiNebe/Nebe_Logo.svg"
import NebeBars from "../../../data/icons/schreinereiNebe/Nebe_bars.svg"
import ShakingButton from "./ShakingButton"

const Container = styled.div`
position:fixed;
top:0;
width:100%;
z-index:5;

font-family:Alegreya Sans;
`
const NavBig = styled.div`
position:relative;
display:none;
@media(min-width:993px) {
    display:felx;
}

-webkit-box-orient:horizontal;

-webkit-box-direction:normal;

-ms-flex-flow:row wrap;

        flex-flow:row wrap;
top:0;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
-webkit-box-pack:end;
-ms-flex-pack:end;
        justify-content:end;
height:2.5em;
background-color:white;
border-bottom:1px solid #ddd;


& p {
    margin:0 20px;
    font-size:1em;
    -webkit-transition: 0.2 ease;
    -o-transition: 0.2 ease;
    transition: 0.2 ease;
}
& p:hover {
    color:#BE0707;
    
}
& svg {
    margin:0 20px;
    height:100%;
    width:3em;
}
`
const NavSmall = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
@media(min-width:993px) {
    display:none;
}
width:100%;
`
const Wrapper = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
@media(min-width:993px) {
    display:none;
}
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
-ms-flex-flow:row wrap;
        flex-flow:row wrap;
top:0;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
-webkit-box-pack:justify;
-ms-flex-pack:justify;
        justify-content:space-between;
height:2.5em;
background-color:white;
border-bottom:1px solid #ddd;
width:100%;
z-index:5;

& svg:nth-child(1) {
    margin:0 20px;
    height:100%;
    width:3em;
}
& svg:nth-child(2) {
    position:relative;
    margin:0 15px;
    height:1.3em;
    width:1.3em;
    -webkit-transition: 0.2 ease;
    -o-transition: 0.2 ease;
    transition: 0.2 ease;

    &:hover {
        -webkit-filter:invert(1);
                filter:invert(1);
        
    }
}
`

const DropDown = styled.div`
position:absolute;
top:2.5em;
width:100%;
-webkit-transition:-webkit-transform 0.6s;
transition:-webkit-transform 0.6s;
-o-transition:transform 0.6s;
transition:transform 0.6s;
transition:transform 0.6s, -webkit-transform 0.6s;
-webkit-transform: translateY(${props => props.showDropdown?("0%"):("-100%")});
-ms-transform: translateY(${props => props.showDropdown?("0%"):("-100%")});
transform: translateY(${props => props.showDropdown?("0%"):("-100%")});
background-color:rgba(255,255,255,0.8);
border-bottom:1px solid #ddd;
z-index:4;
& p {
    margin:10px 20px;
    font-size:1em;
    -webkit-transition: 0.2 ease;
    -o-transition: 0.2 ease;
    transition: 0.2 ease;
    text-align:center;
}
& p:hover {
    color:#BE0707;
    
}
`
const ClosingDiv = styled.div`
position:fixed;
top:0;
left:0;
width:100%;
height:100%;
z-index:3;
display:${props => props.showDropdown?("block"):("none")};
`

class NavBar_SN extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            atTop: true,
            showDropdown: false
        }
    }

    toggleDropdown(){
        this.setState({
            showDropdown: !this.state.showDropdown
        })
    }

    render(){
        return(
            <Container>
                <NavBig>
                        {
                            this.props.data.map((item, i) => (
                                <ShakingButton key={i}>{item.text}</ShakingButton>
                            ))
                        }
                        <NebeLogo/>
                </NavBig>
               <NavSmall>
                   <Wrapper>
                        <NebeLogo/>
                        <NebeBars onClick={this.toggleDropdown.bind(this)}/> 
                   </Wrapper>
                    <DropDown showDropdown={this.state.showDropdown}>
                        <div>
                            {
                                this.props.data.map((item, i) => (
                                    <ShakingButton key={i}>{item.text}</ShakingButton>
                                ))
                            }
                        </div>
                    </DropDown> 
                    <ClosingDiv showDropdown={this.state.showDropdown} onClick={this.toggleDropdown.bind(this)}/>
               </NavSmall>
            </Container>
        )
    }
}

export default NavBar_SN;