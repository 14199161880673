import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NebeBoldBad from "../../../data/icons/schreinereiNebe/Nebe_bold_bad.svg"
import NebeBoldTreppe from "../../../data/icons/schreinereiNebe/Nebe_bold_treppe.svg"
import NebeBoldBett from "../../../data/icons/schreinereiNebe/Nebe_bold_bett.svg"
import NebeBoldPapier from "../../../data/icons/schreinereiNebe/Nebe_bold_papier.svg"


const Container = styled.div`
position:relative;
height:700px;
width:100%;
margin-top:3em;
overflow:hidden;

& .GatImg {
    width:100%;
    height:100%;
    -o-object-fit:cover;
       object-fit:cover;
       z-index:-1;
       
       
}

& .frame {
    display:block;
    -webkit-transition:${props => props.speed}s ease;
    -o-transition:${props => props.speed}s ease;
    transition:${props => props.speed}s ease;
}

& .frame:nth-child(1) {
    position:absolute;
    left:1%;
    top:0%;
    height:100%;
    width:32%;
}
& .frame:nth-child(2) {
    position:absolute;
    left:34%;
    top:0%;
    height:49%;
    width:64%;
}
& .frame:nth-child(3) {
    position:absolute;
    left:34%;
    top:51%;
    height:49%;
    width:32%;
}
& .frame:nth-child(4) {
    position:absolute;
    left:67%;
    top:51%;
    height:49%;
    width:31%;
}

@media (max-width:993px) {
    position:relative;
    height:400px;
    width:100%;
    margin-top:3em;

    & .frame {
        display:none;
        top:0%;
        left:5%;
        
    }

    & .frame:nth-child(${props => props.current+1}) {
        display:block;
        position:absolute;
        height:100%;
        width:90%;
        left:5%;
        top:0;
        opacity:100%;
        & .GatImg {
            -o-object-fit:cover;
               object-fit:cover;
            -o-object-position:50% 60%;
               object-position:50% 60%;
        }
    }
    & .frame:nth-child(${props => props.next+1}) {
        display:block;
        position:absolute;
        height:100%;
        width:90%;
        top:0;
        left:100%;
        margin:auto;
        opacity:0%;

        & .GatImg {
            -o-object-fit:cover;
               object-fit:cover;
            -o-object-position:50% 60%;
               object-position:50% 60%;
        }
        
    }
    & .frame:nth-child(${props => props.prev+1}) {
        display:block;
        position:absolute;
        height:100%;
        width:90%;
        left:-100%;
        top:0;
        margin:auto;
        opacity:0%;
        & .GatImg {
            -o-object-fit:cover;
               object-fit:cover;
            -o-object-position:50% 60%;
               object-position:50% 60%;
        }
        
    }
}
`

const Box = styled.div`
& svg {
    position:absolute;
    margin:20px;
}
`

class Banner_SN extends React.Component {
    constructor(props) {
        super(props);

        this.numSlides = (props.data.Tiles==null)?(0):(props.data.Tiles.length) 
        this.speed = (props.speed==null)?(1):(props.speed)
        this.delay = (props.delay==null)?(5):(props.delay)
        this.start = (props.start==null)?(3):(props.start)
        this.active = true

        this.state = {
            pos:0
        }
    }

    componentDidMount(){
        setTimeout(()=> {
          this.autoSwitch();
        },(this.start)*1000)
      }

    componentWillUnmount() {
        this.active=false
    }
      autoSwitch() {
        if(this.active) {
            setTimeout(()=> {
                this.switchSlides()
                this.autoSwitch()
    

            },this.delay*1000);   
        }  
      }
      
      switchSlides() {
          if(this.state.pos>=this.numSlides-1)
            this.setState({pos:0})
          else
            this.setState({pos:this.state.pos+1})
      }
      switchSlideDirection(s) {
        if(s<0) {
          if(this.state.pos-1<0)
            this.setState({pos:this.numSlides-1})
          else
            this.setState({pos:this.state.pos-1})
        }
        else {
          if(this.state.pos+1>=this.numSlides)
            this.setState({pos:0})
          else
            this.setState({pos:this.state.pos+1})
        }
    }
    
    lockSwipe(e) {
        this.x0 = e.touches[0].clientX
        this.setState({ interrupt:true })
      }

    swipe(e) {
      let dx = e.changedTouches[0].clientX - this.x0
      let s = Math.sign(dx)*(-1)
      if(dx!=0)
        this.switchSlideDirection(s)

    }

    render() {
        return(
            <Container 
            speed={this.speed}
            current={this.state.pos}
            next={(this.state.pos+1==this.numSlides)?(0):(this.state.pos+1)}
            prev={(this.state.pos==0)?(this.numSlides-1):(this.state.pos-1)}

            onTouchStart = {(e) => this.lockSwipe(e)}
            onTouchEnd = {(e) => this.swipe(e)}
            >
                {
                    this.props.data.Tiles.map((Item, i) => (
                        <Box key={i} className="frame">
                            {i==0 && <NebeBoldTreppe/>}
                            {i==1 && <NebeBoldBett/>}
                            {i==2 && <NebeBoldPapier/>}
                            {i==3 && <NebeBoldBad/>}
                            <GatsbyImage loading="eager" className="GatImg" image={(Item.image.src==null)?(""):(getImage(Item.image.src))} alt={Item.image.alt}/>
                            
                        </Box>
                    ))


                }
            </Container>
        )
    }

}
export default Banner_SN;